
import { ref, defineComponent } from 'vue'
import * as api from '../utils/api'
import Review from '../components/Review.vue'
import HeaderComponent from '../components/menu/Public.vue'
import { useDialog } from 'naive-ui'
import { IReview } from '../../interfaces/review'

export default defineComponent({
	components: {
		HeaderComponent,
		Review,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			errorDialog,
			reviews: [] as IReview[],
		}
	},
	mounted: function () {
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const data = (await api.get(`/v1/review`, true)) as IReview[]
				this.reviews = data
				document.title = `口コミ一覧 - Vets Index`
				const head = document.getElementsByTagName('head')[0]
				const meta = document.createElement('meta')
				meta.setAttribute('name', 'description')
				meta.setAttribute('content', '業界初。実習体験記が見られる求人サイト。その実習体験記を少しのぞいてみませんか？')
				head.appendChild(meta)
			} catch (e: any) {
				this.errorDialog(e, () => history.back())
			}
		},
	},
})
