import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_Review = _resolveComponent("Review")!
  const _component_n_layout = _resolveComponent("n-layout")!
  const _component_n_skeleton = _resolveComponent("n-skeleton")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createBlock(_component_n_space, {
    vertical: "",
    style: {"padding":"10px"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderComponent),
      (_ctx.reviews.length)
        ? (_openBlock(), _createBlock(_component_n_layout, {
            key: 0,
            style: {"padding":"10px"},
            ref: "content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Review, {
                reviews: _ctx.reviews,
                showHospitalName: true
              }, null, 8, ["reviews"])
            ]),
            _: 1
          }, 512))
        : (_openBlock(), _createBlock(_component_n_space, {
            key: 1,
            vertical: "",
            style: {"background-color":"white","padding":"10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_skeleton, {
                height: "40px",
                width: "33%"
              }),
              _createVNode(_component_n_skeleton, {
                height: "140px",
                width: "66%",
                sharp: false
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}